import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NConfigProvider"], { "theme-overrides": $setup.themeOverrides }, {
    default: _withCtx(() => [
      _createVNode($setup["NLoadingBarProvider"], null, {
        default: _withCtx(() => [
          _createVNode($setup["NMessageProvider"], null, {
            default: _withCtx(() => [
              _createVNode($setup["NLayout"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["NavBar"]),
                  _createVNode($setup["NLayoutContent"], null, {
                    default: _withCtx(() => [
                      _createVNode($setup["RouterView"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}