const agencyColumns = [
  {
    title: "Code",
    key: "code",
    sorter: "default",
  },
  {
    title: "Name",
    key: "name",
  },
];

const datasetsColumns = [
  {
    title: "Id",
    key: "id",
    sorter: "default",
    minWidth: 100,
  },
  {
    title: "Name",
    key: "name",
    sorter: "default",
    minWidth: 100,
  },
  { title: "Version", key: "version", minWidth: 100 },
  { title: "Description", key: "description", minWidth: 100 },
];

export { agencyColumns, datasetsColumns };
